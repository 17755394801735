const nav = document.querySelector(".navbar-toggler"),
      navToggle = document.querySelector(".navbar-toggler-icon"),
      pageContent = document.getElementById("page-content"),
      form = document.querySelector("#form"),
      submit = document.querySelector("#form button"),
      section = document.querySelectorAll('a'), // change to just a, so behaviour applies to brand
      navContent = document.querySelector('.navbar-collapse');

// handles navigation pageContent
nav.addEventListener("click", function (){
    navToggle.classList.toggle("navbar-toggler-icon");
    navToggle.classList.toggle("fa");
    navToggle.classList.toggle("fa-times");
    pageContent.classList.toggle("blur");
    if (pageContent.className === 'blur') {
        $nav.removeClass("scrolled");
    }
});

// handles clicking nav-item for page already open
for (let i = 0; i < section.length; i++){
    if (section[i].getAttribute('href') === '#'){
        section[i].addEventListener("click", function(){
            if ($('.navbar-collapse').hasClass("show")){
                navToggle.classList.toggle("navbar-toggler-icon");
                navToggle.classList.toggle("fa");
                navToggle.classList.toggle("fa-times");
                pageContent.classList.toggle("blur");
                navContent.classList.remove("show");
            }
        });
    }
}

// handles nav background on scroll event
var $nav = $(".navbar");
$(function () {
    $(document).scroll(function () {
        if (pageContent.className !== 'blur') {
            $nav.toggleClass("scrolled", $(this).scrollTop() > $nav.height());
        }
    });
});

// handles contact form
$(function () {
    $('.contact').click(function(){
        $('.navbar-collapse').removeClass("show");
        form.classList.remove("d-none");
        pageContent.classList.add("blur");
        $nav.removeClass("scrolled");
        navToggle.classList.add("navbar-toggler-icon");
        navToggle.classList.remove("fa", "fa-times");
    });

    submit.addEventListener("click", function (){
        submit.textContent = "Sent!";
    });

    document.querySelector('#form span').addEventListener("click", function(){
        form.classList.add("d-none");
        pageContent.classList.remove("blur");
    });
});

